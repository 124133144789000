import React, { Component } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import Helper from '@/helpers/SiteHelper'

export default class CalendarRow extends Component {
  render() {
    const project = this.props.data
    const projects = this.props.projects
    const options = this.props.options
    const isExternal = project.external_link === 'TRUE'

    return (
      <div
        className={`table__row table__row--${options.columns.length} ${
          project.cancelled === 'TRUE' ? 'table__row--cancelled' : ''
        }`}>
        {options.columns &&
          options.columns.map((col) => {
            if (col.key === 'title') {
              if (isExternal) {
                return (
                  <a
                    className='table__col table__col--link'
                    href={project.url}
                    key={col.key}
                    target='_blank'
                    rel='noreferrer'>
                    <div className='table__col__label'>{col.label}</div>
                    <div className='table__col__val'>
                      {Helper.resolve(col.key, project)}
                    </div>
                  </a>
                )
              } else {
                let url = projects.find(
                  (item) => item.node.frontmatter.title === project.url,
                )
                url = url ? url.node.fields.slug : false
                return (
                  <Link
                    className='table__col table__col--link'
                    to={url}
                    key={col.key}>
                    <div className='table__col__label'>{col.label}</div>
                    <div className='table__col__val'>
                      {Helper.resolve(col.key, project)}
                    </div>
                  </Link>
                )
              }
            } else if (col.key === 'date') {
              return (
                <div className='table__col' key={col.key}>
                  <div className='table__col__label'>{col.label}</div>
                  {project.date ? (
                    <div className='table__col__val'>{project.date}</div>
                  ) : (
                    <div className='table__col__val'>
                      {project.startdate && project.startdate.format('LL')}{' '}
                      {project.enddate
                        ? `- ${moment(project.enddate, 'YYYY-MM-DD').format(
                            'LL',
                          )}`
                        : ''}
                    </div>
                  )}
                </div>
              )
            } else {
              return (
                <div className='table__col' key={col.key}>
                  <div className='table__col__label'>{col.label}</div>
                  <div className='table__col__val'>
                    {Helper.resolve(col.key, project)}
                  </div>
                </div>
              )
            }
          })}
      </div>
    )
  }
}
