import React, { Component } from 'react';
import Fuse from 'fuse.js';
// import { urlToKey, getWorkbook, getSheet } from 'sheetsy'
import moment from 'moment';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import CalendarRow from '@/components/Calendar/partials/Row';

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSwitcher: 'upcoming',
      fuse: [],
      projects: this.props.projects,
      query: '',
      pastEvents: false,
      events: {},
      upcomingEvents: false,
      pastFuse: [],
      upcomingFuse: [],
    };
    this.handleSwitchClick = this.handleSwitchClick.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.prepareEvents = this.prepareEvents.bind(this);
  }

  componentDidMount() {
    const options = {
      keys: ['node.frontmatter.title', 'node.frontmatter.details.client'],
    };

    this.setState({
      fuse: new Fuse(this.props.projects, options),
    });

    let sheetID = '1D7r_R94AHI33KgogokW9fo6eIV771mZjPQJhuulgZpE';
    let sheetName = 'Events';
    let apiKey = 'AIzaSyD-fyLY5DapIlPayPFoEQB2xteEj2aFyUk';

    fetch(
      'https://sheets.googleapis.com/v4/spreadsheets/' +
        sheetID +
        '/values/' +
        sheetName +
        '?key=' +
        apiKey,
    )
      .then((res) => res.json())
      .then((json) => {
        const keys = json.values[0];
        const values = json.values.slice(1);
        const items = values.map((array) => {
          const object = {};
          keys.forEach((key, i) => (object[key] = array[i]));
          return object;
        });
        let events = items.filter((item) => item.published === 'TRUE');
        this.prepareEvents(events);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSwitchClick(val) {
    this.setState({
      activeSwitcher: val,
    });
  }

  prepareEvents(events) {
    events.forEach((event) => {
      event.startdate = moment(event.startdate, 'YYYYMMDD');
      event.enddate = event.enddate ? moment(event.enddate, 'YYYYMMDD') : false;
    });
    events.sort(
      (a, b) => a.startdate.format('YYYYMMDD') - b.startdate.format('YYYYMMDD'),
    );
    let pastEvents = events.filter((event) => {
      if (!event.enddate) {
        return moment().subtract(1, 'days') > event.startdate;
      }

      return moment().subtract(1, 'days') > event.enddate;
    });

    pastEvents.sort(
      (a, b) => b.startdate.format('YYYYMMDD') - a.startdate.format('YYYYMMDD'),
    );

    let upcomingEvents = events.filter((event) => {
      return moment() <= event.startdate || moment() <= event.enddate;
    });

    const options = {
      keys: ['title', 'where', 'details', 'role'],
    };

    this.setState({
      activeSwitcher: 'upcoming',
      upcomingEvents: upcomingEvents,
      upcomingFuse: new Fuse(upcomingEvents, options),
      pastEvents: pastEvents,
      pastFuse: new Fuse(pastEvents, options),
      events: {
        past: pastEvents,
        upcoming: upcomingEvents,
      },
    });
  }

  handleInput(e) {
    let searchInput = e.target.value;
    this.setState((state) => {
      return {
        query: searchInput,
        projects:
          searchInput.length > 0
            ? state.fuse.search(searchInput).map((i) => i.item)
            : this.props.projects,
        pastEvents:
          searchInput.length > 0
            ? state.pastFuse.search(searchInput).map((i) => i.item)
            : this.state.events.past,
        upcomingEvents:
          searchInput.length > 0
            ? state.upcomingFuse.search(searchInput).map((i) => i.item)
            : this.state.events.upcoming,
      };
    });
  }

  render() {
    const options = this.props.options;
    return (
      <section
        className={`calendar-section ${this.props.full ? 'full-size' : ''}`}>
        <div className='container'>
          <section className='calendar'>
            <div className='calendar__header'>
              <h2 data-aos='fade-right' className='tablet-title'>
                {options.title}
              </h2>
              {/* {options.search && (
                <input
                  type='text'
                  className='search-input'
                  data-aos='fade-left'
                  placeholder='Search..'
                  value={this.state.query}
                  onChange={this.handleInput}
                />
              )} */}
            </div>
            {options.switcher && (
              <div className='calendar__switcher' data-aos='fade-up'>
                <button
                  className={
                    this.state.activeSwitcher === 'upcoming'
                      ? 'switch switch--active'
                      : 'switch'
                  }
                  onClick={() => {
                    this.handleSwitchClick('upcoming');
                  }}>
                  {' '}
                  Upcoming events
                </button>
                {this.state.pastEvents.length > 0 && (
                  <button
                    className={
                      this.state.activeSwitcher === 'past'
                        ? 'switch switch--active'
                        : 'switch'
                    }
                    onClick={() => {
                      this.handleSwitchClick('past');
                    }}>
                    Past events
                  </button>
                )}
              </div>
            )}
            <div className='calendar__body' data-aos='fade-up'>
              <div className='calendar__table table'>
                <div
                  data-aos='fade-up'
                  className={`table__row table__row--header table__row--${options.columns.length}`}>
                  {options.columns &&
                    options.columns.map((col, i) => (
                      <div className='table__col table__col--header' key={i}>
                        {col.label}
                      </div>
                    ))}
                </div>{' '}
                <SwitchTransition mode='out-in'>
                  <CSSTransition
                    key={this.state.activeSwitcher}
                    addEndListener={(node, done) => {
                      node.addEventListener('transitionend', done, false);
                    }}
                    classNames='fade'>
                    <div className='table__body'>
                      {this.state.activeSwitcher === 'upcoming'
                        ? this.state.upcomingEvents &&
                          this.state.upcomingEvents.length > 0 &&
                          this.state.upcomingEvents.map((project, index) => (
                            <CalendarRow
                              key={project.id || index}
                              data={project}
                              projects={this.props.projects}
                              options={options}
                              index={index}></CalendarRow>
                          ))
                        : this.state.pastEvents &&
                          this.state.pastEvents.length > 0 &&
                          this.state.pastEvents.map((project, index) => (
                            <CalendarRow
                              key={project.id || index}
                              projects={this.props.projects}
                              data={project}
                              options={options}
                              index={index}></CalendarRow>
                          ))}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </section>
        </div>
      </section>
    );
  }
}
