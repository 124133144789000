import React, { useState, useEffect, useRef } from 'react'
import Typewriter from 'typewriter-effect'

export default function Video({
  src,
  imageSrc,
  mobileSrc,
  mobileImageSrc,
  resetScroll,
  quotes,
}) {
  const isBrowser = typeof window !== 'undefined'
  const [isPlaying, setIsPlaying] = useState(true)
  const [videoSrc, setVideoSrc] = useState(null)
  const [imgSrc, setImgSrc] = useState(null)
  const [showVideo, setShowVideo] = useState(true)
  const videoRef = useRef(null)

  useEffect(() => {
    if (!isBrowser) return false

    const handleResize = () => {
      if (window.innerWidth >= 991) {
        setVideoSrc(src)
        setImgSrc(imageSrc)
      } else {
        setImgSrc(mobileImageSrc)
        setVideoSrc(mobileSrc)
      }

      resetScroll()
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (showVideo) {
      if (isPlaying) {
        let video = videoRef.current
        if (
          video.currentTime > 0 &&
          !video.ended &&
          video.readyState > video.HAVE_CURRENT_DATA
        ) {
          videoRef.current.play()
        }
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  const [isMuted, setIsMuted] = useState(true)

  const btnClass = `btn-play btn-play--${isPlaying ? 'pause' : 'play'}`
  const mutedClass = `btn-play btn-play--mute ${
    isMuted ? 'btn-play--muted' : ''
  }`

  const heroClass = `hero hero--video full-size`

  return (
    <>
      {showVideo ? (
        <div className={heroClass}>
          <div
            className='video-wrapper'
            style={{ backgroundImage: `url(${imgSrc})` }}>
            <video
              src={videoSrc}
              poster={imgSrc}
              autoPlay
              loop
              playsInline
              muted={isMuted}
              ref={videoRef}
              className='Video'></video>
            {quotes.show ? (
              <div className='Video__content'>
                <div className='container'>
                  <Typewriter
                    options={{
                      strings: quotes.quotes,
                      autoStart: true,
                      loop: true,
                      delay: 75,
                      deleteSpeed: 10,
                      pauseFor: 3200,
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='Video__controls'>
              <div className='container'>
                <div className='Video__controls-wrapper'>
                  <div
                    className={btnClass}
                    onClick={() => setIsPlaying(!isPlaying)}>
                    <span className='btn-play__bar btn-play__bar--1'></span>
                    <span className='btn-play__bar btn-play__bar--2'></span>
                  </div>
                  <div
                    className={mutedClass}
                    onClick={() => setIsMuted(!isMuted)}>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      className='prefix__feather prefix__feather-volume-1'>
                      <path d='M11 5L6 9H2v6h4l5 4V5zM15.54 8.46a5 5 0 010 7.07' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
