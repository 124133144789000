import React, { Component } from 'react'
import { Link } from 'gatsby'

import Image from '@/components/Image'
import Attribute from '@/components/Attribute'

export default class Project extends Component {
  render() {
    const project = this.props.project
    const details = this.props.project.frontmatter.details

    return (
      <article className='LargeProject full-size'>
        <div className='LargeProject__permalink'>
          {project.frontmatter.thumbnail && (
            <Image
              className='LargeProject__image background'
              aos='fade-in'
              loading='lazy'
              author={project.frontmatter.thumbnail.author}
              image={project.frontmatter.thumbnail.image}
            />
          )}
          <div className='container'>
            <div className='card' data-aos='fade-up'>
              <a
                className='project__name title-5 text-dark mt-0 mb-40'
                href={project.fields.slug}>
                <span>{project.frontmatter.title}</span>
              </a>
              <div className='attributes'>
                {details &&
                  Object.keys(details).map((key, i) => (
                    <Attribute
                      name={key}
                      key={i}
                      value={details[key]}></Attribute>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    )
  }
}
