import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'

import Layout from '@/components/Layout'
import Image from '@/components/Image'
import LargeProject from '@/components/Projects/LargeProjectItem'
import Calendar from '@/components/Calendar/Calendar'
import HeroVideo from '@/components/Video'
import Helper from '@/helpers/SiteHelper'

class HomePageTemplate extends React.Component {
  resetScroll() {
    Helper.refreshAOS()
  }

  render() {
    const calendarSetup = {
      title: 'Events calendar',
      switcher: true,
      search: true,
      columns: [
        {
          label: 'Event',
          key: 'title',
        },
        {
          label: 'Date',
          key: 'date',
        },
        {
          label: 'Where',
          key: 'where',
        },
        {
          label: 'Details',
          key: 'details',
        },
        {
          label: 'Dionysus’ Role',
          key: 'role',
        },
      ],
    }

    const hero = this.props.hero
    const image = this.props.image
    const projects = this.props.projects
    const promoted = this.props.promoted

    let sortedProjects = []
    projects.forEach(({ node: project }) => {
      sortedProjects[promoted.indexOf(project.frontmatter.id)] = project
    })

    return (
      <>
        <HeroVideo
          src='https://cdn.cleancommit.io/dionysus/dionysus-brand-video.mp4'
          imageSrc='/img/dionysus-desktop-image.jpg'
          mobileSrc='https://cdn.cleancommit.io/dionysus/dionysus-brand-video-mobile.mp4'
          mobileImageSrc='/img/dionysus-mobile.jpg'
          resetScroll={this.resetScroll}
          quotes={this.props.quotes}
        />
        <section className='hero full-size'>
          <GatsbyImage
            image={getImage(image)}
            loading='lazy'
            alt=''
            className='background op-30'
          />
          <div className='hero__wrapper'>
            <div className='container'>
              <div className='hero__inner'>
                <h1 className='hero__title tablet-title' data-aos='fade-right'>
                  {hero.heading}
                </h1>
                <div data-aos='fade-right'>
                  <ReactMarkdown
                    className='hero__content'
                    children={hero.description}></ReactMarkdown>
                </div>
              </div>

              <div className='hero__images'>
                <Image
                  className='hero-image hero-image--1'
                  aos='fade-left'
                  author={hero.image1.author}
                  image={hero.image1.image}
                />

                <Image
                  className='hero-image hero-image--2'
                  aos='fade-up'
                  author={hero.image2.author}
                  image={hero.image2.image}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='background-wrapper' id='calendar'>
          {this.props.background ? (
            <GatsbyImage
              image={getImage(this.props.background)}
              alt='People in an event'
              className='background op-5'
            />
          ) : (
            ''
          )}

          <Calendar
            options={calendarSetup}
            full={true}
            projects={projects}
            bg={this.props.background}></Calendar>
        </section>
        <section>
          {sortedProjects &&
            sortedProjects.map((project) => (
              <LargeProject key={project.id} project={project}></LargeProject>
            ))}
        </section>
      </>
    )
  }
}

HomePageTemplate.propTypes = {
  hero: PropTypes.object,
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: projects } = data.projects

  return (
    <Layout>
      <Helmet>
        <title>Dionysus | Cultural Development</title>
        <meta property='og:title' content='Dionysus | Cultural Development' />
      </Helmet>
      <HomePageTemplate
        image={frontmatter.image}
        hero={frontmatter.hero}
        promoted={frontmatter.projects}
        projects={projects}
        quotes={frontmatter.quotes}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query HomePageTemplate($projects: [String!]) {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        quotes {
          quotes
          show
        }
        hero {
          heading
          description
          image1 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 385
                  quality: 45
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            author
          }
          image2 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 385
                  quality: 45
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            author
          }
        }
        projects
      }
    }
    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "project" }
          id: { in: $projects }
          secret: { eq: false }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            id
            title
            video {
              active
              url
            }
            thumbnail {
              author
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000
                    quality: 75
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            details {
              client
              purpose
              roles
              when
              initiatives
            }
          }
        }
      }
    }
  }
`
